// utils.js

import Vue from "vue";

// Function to validate fields and confirm action
export function validateAndConfirm(
  currentValue,
  newValue,
  fieldName,
  updateCallback
) {
  if (currentValue === newValue) {
    Vue.toasted.error(`${fieldName} has not changed.`, { duration: 2000 });
    return false;
  }

  if (!newValue) {
    Vue.toasted.error(`${fieldName} cannot be empty.`, { duration: 2000 });
    return false;
  }

  if (!confirm(`Save changes to ${fieldName}?`)) {
    return false;
  }

  updateCallback();
  return true;
}

function showError(message) {
  Vue.toasted.error(message, { duration: 2000 });
}

function isSeverityValid(severity) {
  return severity >= 1 && severity <= 5;
}

function confirmChanges() {
  return confirm(`Save changes to Severity?`);
}

export function validateAndConfirmSeverity(
  currentSeverity,
  newSeverity,
  updateCallback
) {
  let isValid = true;

  if (!newSeverity) {
    showError(`Severity cannot be empty.`);
    isValid = false;
  }

  if (currentSeverity === newSeverity) {
    showError(`Severity has not changed.`);
    isValid = false;
  }

  if (!isSeverityValid(newSeverity)) {
    showError("Severity must be between 1 and 5.");
    isValid = false;
  }

  if (!isValid || !confirmChanges()) {
    return false;
  }

  updateCallback();
  return true;
}
