<template>
  <v-app>
    <v-container fluid>
      <!-- Loading Spinner -->
      <v-progress-circular
        v-if="jiraLoading"
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>

      <!-- Ticket Summary Section -->
      <v-card class="mb-4 elevation-1" v-else>
        <v-card-title>
          <v-icon left>mdi-ticket-outline</v-icon>
          Ticket Summary
        </v-card-title>
        <v-card-subtitle>
          <v-chip
            color="primary"
            text-color="white"
            :href="jiraUrl"
            target="_blank"
          >
            Open in Jira
          </v-chip>
        </v-card-subtitle>
        <v-card-text class="jira-details">
          <v-row>
            <v-col cols="6"> <strong>Jira Key:</strong> {{ jiraKey }} </v-col>
            <v-col cols="6">
              <strong>Original Title:</strong> {{ selectedItem.title }}
            </v-col>
            <v-col cols="12">
              <strong>Original Description:</strong>
              <p>{{ selectedItem.description }}</p>
            </v-col>
            <v-col cols="12" v-if="patientIds">
              <strong>Patient IDs:</strong> {{ patientIds }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Edit Ticket Details Section -->
      <v-row v-if="!jiraLoading">
        <v-col cols="12" md="6">
          <v-card class="mb-4 elevation-1">
            <v-card-title>
              <v-icon left>mdi-pencil-outline</v-icon>
              Edit AI Suggested Ticket Details
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Suggested Title"
                class="suggested-title"
                v-model="suggestedTitle"
                prepend-inner-icon="mdi-pencil-outline"
                outlined
              />
              <v-btn color="primary" @click="saveTitle()" class="mt-2 save-btn">
                Save AI Suggested Title
              </v-btn>
              <v-textarea
                label="Suggested Description"
                v-model="suggestedDescription"
                prepend-inner-icon="mdi-pencil-outline"
                outlined
                rows="4"
              />
              <v-btn color="primary" @click="saveDescription()" class="mt-2">
                Save AI Suggested Description
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <!-- Severity and Justification Section -->
          <v-card class="mb-4 elevation-1">
            <v-card-title>
              <v-icon left>mdi-alert-outline</v-icon>
              Severity & Justification
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Suggested Severity"
                v-model="severity"
                prepend-inner-icon="mdi-alert-circle-outline"
                outlined
                type="number"
                min="1"
                max="5"
              />
              <v-btn color="primary" @click="saveSeverity()" class="mt-2">
                Save Severity
              </v-btn>
              <v-textarea
                label="Justification"
                v-model="severityJustification"
                prepend-inner-icon="mdi-comment-outline"
                outlined
                rows="4"
              />
              <v-btn color="primary" @click="saveJustification()" class="mt-2">
                Save Justification
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Linked & Suggested Tickets Section -->
      <v-card class="mb-4 elevation-1" v-if="!jiraLoading">
        <v-card-title>
          <v-icon left>mdi-link-variant"></v-icon>
          Linked & Suggested Tickets
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-subheader>Linked Tickets</v-subheader>
            <v-list-item
              v-for="(ticket, index) in selectedItem.linkedVendorTickets"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  ><a :href="ticket.url" target="_blank">
                    {{ ticket.issueId }} - {{ ticket.title }}
                  </a></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-subheader>Suggested Tickets</v-subheader>
            <v-list-item
              v-for="(ticket, index) in selectedItem.suggestedTickets"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  ><a :href="ticket.url" target="_blank">
                    {{ ticket.issueId }} - {{ ticket.title }}
                  </a></v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="linkGitlab(ticket)" class="link-git">
                  <v-icon>mdi-link-plus</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-text-field
            label="Add new ticket URL"
            v-model="newTicketUrl"
            prepend-inner-icon="mdi-link"
            outlined
            class="mt-4"
          />
          <v-btn
            color="success"
            @click="linkGitlab(newTicketUrl)"
            class="mt-2 link-git"
          >
            Add Ticket
          </v-btn>
        </v-card-text>
      </v-card>

      <!-- Create GitLab Ticket Section -->
      <v-card class="mb-4 elevation-1" v-if="!jiraLoading">
        <v-card-title>
          <v-icon left>mdi-plus-box-outline"></v-icon>
          Create GitLab Ticket
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Gitlab Title"
            v-model="gitlabTitle"
            prepend-inner-icon="mdi-pencil-outline"
            outlined
          />
          <v-textarea
            label="Gitlab Description"
            v-model="gitlabDescription"
            prepend-inner-icon="mdi-pencil-outline"
            outlined
            rows="4"
          />
          <v-btn color="primary" @click="createGitlab" class="mt-2 create-git">
            Create Ticket
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import {
  validateAndConfirmSeverity,
  validateAndConfirm,
} from "../../utils/TicketMangementUtils/dataValidation";
import router from "./../router/index";
import { routeNames } from "../../utils/dataValidations";

export default {
  data() {
    return {
      jiraLoading: true,
      selectedItem: {},
      newTicketUrl: null,
      suggestedTitle: null,
      suggestedDescription: null,
      severity: null,
      severityJustification: null,
      jiraUrl: null,
      jiraKey: null,
      internalNote: null,
      suggestedResponse: null,
      gitlabTitle: null,
      gitlabDescription: null,
      patientIds: null,
    };
  },
  computed: {
    ...mapState(["jiraTicket", "isAuth", "isProd", "scopes"]),
    shouldDisplayLinkedTickets() {
      return (
        this.selectedItem &&
        this.selectedItem.linkedVendorTickets &&
        this.selectedItem.linkedVendorTickets.length > 0
      );
    },
    shouldDisplaySuggestedVendorTickets() {
      return (
        this.selectedItem &&
        this.selectedItem.suggestedTickets &&
        this.selectedItem.suggestedTickets.length > 0
      );
    },
  },
  async mounted() {
    if (!this.isAuth) {
      return router.push(routeNames.Home);
    }
    if (this.isProd && !this.scopes.includes("ticket-admin")) {
      return router.push(routeNames.Welcome);
    }
    this.setDeploymentName(this.$route.query.platform);
    this.ticketId = this.$route.params.id;
    await this.fetchTicketDetailById(this.ticketId);
  },
  methods: {
    ...mapActions([
      "fetchJira",
      "updateJira",
      "linkTickets",
      "addComment",
      "createGitlabTicket",
      "setDeploymentName",
    ]),
    async fetchTicketDetailById(id) {
      this.jiraLoading = true;
      await this.fetchJira({ path: `/v1/jira/${id}` });
      this.selectedItem = this.jiraTicket || {};
      ({
        suggestedTitle: this.suggestedTitle,
        suggestedDescription: this.suggestedDescription,
        severity: this.severity,
        severityJustification: this.severityJustification,
        key: this.jiraKey,
        url: this.jiraUrl,
        suggestedResponse: this.suggestedResponse,
        patientIds: this.patientIds,
      } = this.selectedItem);
      this.gitlabTitle = this.selectedItem.suggestedTitle;
      this.gitlabDescription = this.selectedItem.suggestedDescription;
      this.jiraLoading = false;
    },
    saveTitle() {
      validateAndConfirm(
        this.selectedItem.suggestedTitle,
        this.suggestedTitle,
        "Suggested Title",
        async () => {
          await this.updateJira({
            data: {
              key: this.selectedItem.key,
              title: this.suggestedTitle,
            },
            path: "/v1/jira",
          });
        }
      );
    },
    saveDescription() {
      validateAndConfirm(
        this.selectedItem.suggestedDescription,
        this.suggestedDescription,
        "Suggested Description",
        async () => {
          await this.updateJira({
            data: {
              key: this.selectedItem.key,
              description: this.suggestedDescription,
            },
            path: "/v1/jira",
          });
        }
      );
    },
    saveJustification() {
      validateAndConfirm(
        this.selectedItem.severityJustification,
        this.severityJustification,
        "Justification",
        async () => {
          const response = await this.updateJira({
            data: {
              key: this.selectedItem.key,
              severityJustification: this.severityJustification,
            },
            path: "/v1/jira",
          });
          if (response.success) {
            this.selectedItem.severityJustification =
              response.data.severityJustification;
          }
        }
      );
    },
    async saveSeverity() {
      validateAndConfirmSeverity(
        this.selectedItem.severity,
        this.severity,
        async () => {
          const response = await this.updateJira({
            data: {
              key: this.selectedItem.key,
              severity: this.severity,
            },
            path: "/v1/jira",
          });
          if (response.success) {
            this.selectedItem.severity = response.data.severity;
          }
        }
      );
    },
    async linkGitlab(url) {
      const response = await this.linkTickets({
        data: {
          key: this.selectedItem.key,
          gitlabUrl: url,
        },
        path: "/v1/jira-gitlab",
      });
      if (response.success) {
        const issueIdMatch = response.data.gitlabUrl.match(/\/issues\/(\d+)$/);
        const issueId = issueIdMatch ? issueIdMatch[1] : null;
        this.selectedItem.linkedVendorTickets.push({
          key: issueId,
          url: response.data.gitlabUrl,
        });
        this.selectedItem.suggestedVendorTicketsList =
          this.selectedItem.suggestedVendorTicketsList.filter(
            (url) => url !== response.data.gitlabUrl
          );
        this.newTicketUrl = null;
      }
    },
    getTicketKey(url) {
      return url.split("/").pop();
    },
    async createGitlab() {
      const response = await this.createGitlabTicket({
        data: {
          key: this.selectedItem.key,
          title: this.gitlabTitle,
          description: this.gitlabDescription,
        },
        path: "/v1/gitlab",
      });
      if (response.success) {
        Vue.toasted.success("GitLab ticket created successfully", {
          duration: 2000,
        });
      } else {
        Vue.toasted.error("Failed to create GitLab ticket", { duration: 2000 });
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out;
}
.v-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
.v-card-title {
  font-size: 1.25rem;
  font-weight: 500;
}
.mb-4 {
  margin-bottom: 16px;
}
.mt-2 {
  margin-bottom: 16px;
}
a {
  text-decoration: none;
}
.jira-details {
  font-size: 15px;
}
</style>
